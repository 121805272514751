//#preloader {
//	top: 0;
//	left: 0;
//	height: 100%;
//	background-color: #f2f2f200;
//	z-index: 2;
//	-webkit-box-pack: center;
//	-ms-flex-pack: center;
//	justify-content: center;
//	overflow: hidden;
//	-webkit-user-select: none;
//	-moz-user-select: none;
//	-ms-user-select: none;
//	user-select: none;
//	pointer-events: none
//}
//
//#preloader, .bckgrdPreloader, .loadBar {
//	position: absolute;
//	width: 100%
//}
//
//.bckgrdPreloader {
//	position: absolute;
//	top: 0px;
//	left: 0px;
//	width: 100%;
//	height: 100%;
//	background-color: #fafafa;
//}
//
//#img_cont {
//	display: none;
//}
//
//.hide-preloader {
//	transition: visibility 0.9s, opacity 1s ease-in;
//	visibility: hidden;
//	opacity: 0;
//}
//
//.show-scene {
//	transition: opacity 0.3s ease-in;
//	opacity: 0;
//}
//
//#preloadCont {
//	position: absolute;
//	top: 50%;
//	left: 50%;
//	margin-right: -50%;
//	transform: translate(-50%, -50%);
//	width: 80vw;
//	height: 80vw;
//	z-index: 1;
//
//	@include r($xs-breakpoint) {
//		width: 165vw;
//		height: 165vw;
//	}
//}
//
//.hide-preloadCont{
//    transition: opacity 0.3s ease-in;
//    opacity: 0;
//}


#preloader {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-color: #f2f2f200;
	z-index: 2;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	user-select: none;
	pointer-events: none;
}

.bckgrdPreloader{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-color: #fafafa;
}

.loadBar {
	position: absolute;
	bottom: 0px;
	right: 100%;
	width: 100%;
	height: 12px;
	background-color: #faea0a;
}

#preloader img {
	max-width: inherit;
}

#txt_load {
	margin: 0;
	padding: 0;
	list-style: none;
	font-size: 4em;
	transform-style: preserve-3d;
	animation: three-dimensions-circle 30s linear infinite;
}

#img_cont {
	position: absolute;
	transform-style: preserve-3d;
	margin: 0;
	list-style: none;
	font-size: 4em;
	animation: bck 30s linear infinite;
}

.img_preloader {
	position: absolute;
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: 320px;
	transition: 0.55s ease-in-out;
	animation: up_down 1s ease-in-out alternate infinite;
}

.img-hide {
	opacity: 0;
}

.loading {
	position: absolute;
	transform-style: preserve-3d;
	top: 50%;
	left: 50%;
	color: #943dd9;
}

.loading:nth-child(1) {
	transform: translate(-50%, -50%) rotate(0deg) translateY(-200px) rotateX(90deg);
}

.loading:nth-child(2) {
	transform: translate(-50%, -50%) rotate(10deg) translateY(-200px) rotateX(90deg);
}

.loading:nth-child(3) {
	transform: translate(-50%, -50%) rotate(20deg) translateY(-200px) rotateX(90deg);
}

.loading:nth-child(4) {
	transform: translate(-50%, -50%) rotate(30deg) translateY(-200px) rotateX(90deg);
}

.loading:nth-child(5) {
	transform: translate(-50%, -50%) rotate(40deg) translateY(-200px) rotateX(90deg);
}

.loading:nth-child(6) {
	transform: translate(-50%, -50%) rotate(50deg) translateY(-200px) rotateX(90deg);
}

.loading:nth-child(7) {
	transform: translate(-50%, -50%) rotate(60deg) translateY(-200px) rotateX(90deg);
}

.loading:nth-child(8) {
	transform: translate(-50%, -50%) rotate(70deg) translateY(-200px) rotateX(90deg);
}

.loading:nth-child(9) {
	transform: translate(-50%, -50%) rotate(80deg) translateY(-200px) rotateX(90deg);
}

.loading:nth-child(10) {
	transform: translate(-50%, -50%) rotate(90deg) translateY(-200px) rotateX(90deg);
}

.loading:nth-child(11) {
	transform: translate(-50%, -50%) rotate(100deg) translateY(-200px) rotateX(90deg);
}

.loading:nth-child(12) {
	transform: translate(-50%, -50%) rotate(110deg) translateY(-200px) rotateX(90deg);
}

.loading:nth-child(13) {
	transform: translate(-50%, -50%) rotate(120deg) translateY(-200px) rotateX(90deg);
}

.loading:nth-child(14) {
	transform: translate(-50%, -50%) rotate(130deg) translateY(-200px) rotateX(90deg);
}

.loading:nth-child(15) {
	transform: translate(-50%, -50%) rotate(140deg) translateY(-200px) rotateX(90deg);
}

.loading:nth-child(16) {
	transform: translate(-50%, -50%) rotate(150deg) translateY(-200px) rotateX(90deg);
}

.loading:nth-child(17) {
	transform: translate(-50%, -50%) rotate(160deg) translateY(-200px) rotateX(90deg);
}

.loading:nth-child(18) {
	transform: translate(-50%, -50%) rotate(170deg) translateY(-200px) rotateX(90deg);
}

.loading:nth-child(19) {
	transform: translate(-50%, -50%) rotate(180deg) translateY(-200px) rotateX(90deg);
}

.loading:nth-child(20) {
	transform: translate(-50%, -50%) rotate(190deg) translateY(-200px) rotateX(90deg);
}

.loading:nth-child(21) {
	transform: translate(-50%, -50%) rotate(200deg) translateY(-200px) rotateX(90deg);
}

.loading:nth-child(22) {
	transform: translate(-50%, -50%) rotate(210deg) translateY(-200px) rotateX(90deg);
}

.loading:nth-child(23) {
	transform: translate(-50%, -50%) rotate(220deg) translateY(-200px) rotateX(90deg);
}

.loading:nth-child(24) {
	transform: translate(-50%, -50%) rotate(230deg) translateY(-200px) rotateX(90deg);
}

.loading:nth-child(25) {
	transform: translate(-50%, -50%) rotate(240deg) translateY(-200px) rotateX(90deg);
}

.loading:nth-child(26) {
	transform: translate(-50%, -50%) rotate(250deg) translateY(-200px) rotateX(90deg);
}

.loading:nth-child(27) {
	transform: translate(-50%, -50%) rotate(260deg) translateY(-200px) rotateX(90deg);
}

.loading:nth-child(28) {
	transform: translate(-50%, -50%) rotate(270deg) translateY(-200px) rotateX(90deg);
}

.loading:nth-child(29) {
	transform: translate(-50%, -50%) rotate(280deg) translateY(-200px) rotateX(90deg);
}

.loading:nth-child(30) {
	transform: translate(-50%, -50%) rotate(290deg) translateY(-200px) rotateX(90deg);
}

.loading:nth-child(31) {
	transform: translate(-50%, -50%) rotate(300deg) translateY(-200px) rotateX(90deg);
}

.loading:nth-child(32) {
	transform: translate(-50%, -50%) rotate(310deg) translateY(-200px) rotateX(90deg);
}

.loading:nth-child(33) {
	transform: translate(-50%, -50%) rotate(320deg) translateY(-200px) rotateX(90deg);
}

.loading:nth-child(34) {
	transform: translate(-50%, -50%) rotate(330deg) translateY(-200px) rotateX(90deg);
}

.loading:nth-child(35) {
	transform: translate(-50%, -50%) rotate(340deg) translateY(-200px) rotateX(90deg);
}

.loading:nth-child(36) {
	transform: translate(-50%, -50%) rotate(350deg) translateY(-200px) rotateX(90deg);
}


@keyframes three-dimensions-circle {
	0% {
		transform: rotateX(-100deg) rotate(0);
	}

	100% {
		transform: rotateX(-100deg) rotate(-360deg);
	}
}

@keyframes bck {
	0% {
		transform: translate(-50%, -50%) rotateX(90deg) rotateY(0deg) rotateZ(0deg);
	}

	100% {
		transform: translate(-50%, -50%) rotateX(90deg) rotateY(360deg) rotateZ(0deg);
	}
}

@keyframes up_down {
	0% {
		transform: translate(-50%, -50%);
	}

	100% {
		transform: translate(-50%, -50%);
	}
}

.hide-preloader {
	transition: visibility 0.9s, opacity 1s ease-in;
	visibility: hidden;
	opacity: 0;
}
.show-scene{
	transition: opacity 0.3s ease-in;
	opacity: 0;
}

@media screen and (max-width: 1024px) {
	.loading:nth-child(1) {
		transform: translate(-50%, -50%) rotate(0deg) translateY(-100px) rotateX(90deg);
	}

	.loading:nth-child(2) {
		transform: translate(-50%, -50%) rotate(10deg) translateY(-100px) rotateX(90deg);
	}

	.loading:nth-child(3) {
		transform: translate(-50%, -50%) rotate(20deg) translateY(-100px) rotateX(90deg);
	}

	.loading:nth-child(4) {
		transform: translate(-50%, -50%) rotate(30deg) translateY(-100px) rotateX(90deg);
	}

	.loading:nth-child(5) {
		transform: translate(-50%, -50%) rotate(40deg) translateY(-100px) rotateX(90deg);
	}

	.loading:nth-child(6) {
		transform: translate(-50%, -50%) rotate(50deg) translateY(-100px) rotateX(90deg);
	}

	.loading:nth-child(7) {
		transform: translate(-50%, -50%) rotate(60deg) translateY(-100px) rotateX(90deg);
	}

	.loading:nth-child(8) {
		transform: translate(-50%, -50%) rotate(70deg) translateY(-100px) rotateX(90deg);
	}

	.loading:nth-child(9) {
		transform: translate(-50%, -50%) rotate(80deg) translateY(-100px) rotateX(90deg);
	}

	.loading:nth-child(10) {
		transform: translate(-50%, -50%) rotate(90deg) translateY(-100px) rotateX(90deg);
	}

	.loading:nth-child(11) {
		transform: translate(-50%, -50%) rotate(100deg) translateY(-100px) rotateX(90deg);
	}

	.loading:nth-child(12) {
		transform: translate(-50%, -50%) rotate(110deg) translateY(-100px) rotateX(90deg);
	}

	.loading:nth-child(13) {
		transform: translate(-50%, -50%) rotate(120deg) translateY(-100px) rotateX(90deg);
	}

	.loading:nth-child(14) {
		transform: translate(-50%, -50%) rotate(130deg) translateY(-100px) rotateX(90deg);
	}

	.loading:nth-child(15) {
		transform: translate(-50%, -50%) rotate(140deg) translateY(-100px) rotateX(90deg);
	}

	.loading:nth-child(16) {
		transform: translate(-50%, -50%) rotate(150deg) translateY(-100px) rotateX(90deg);
	}

	.loading:nth-child(17) {
		transform: translate(-50%, -50%) rotate(160deg) translateY(-100px) rotateX(90deg);
	}

	.loading:nth-child(18) {
		transform: translate(-50%, -50%) rotate(170deg) translateY(-100px) rotateX(90deg);
	}

	.loading:nth-child(19) {
		transform: translate(-50%, -50%) rotate(180deg) translateY(-100px) rotateX(90deg);
	}

	.loading:nth-child(20) {
		transform: translate(-50%, -50%) rotate(190deg) translateY(-100px) rotateX(90deg);
	}

	.loading:nth-child(21) {
		transform: translate(-50%, -50%) rotate(200deg) translateY(-100px) rotateX(90deg);
	}

	.loading:nth-child(22) {
		transform: translate(-50%, -50%) rotate(210deg) translateY(-100px) rotateX(90deg);
	}

	.loading:nth-child(23) {
		transform: translate(-50%, -50%) rotate(220deg) translateY(-100px) rotateX(90deg);
	}

	.loading:nth-child(24) {
		transform: translate(-50%, -50%) rotate(230deg) translateY(-100px) rotateX(90deg);
	}

	.loading:nth-child(25) {
		transform: translate(-50%, -50%) rotate(240deg) translateY(-100px) rotateX(90deg);
	}

	.loading:nth-child(26) {
		transform: translate(-50%, -50%) rotate(250deg) translateY(-100px) rotateX(90deg);
	}

	.loading:nth-child(27) {
		transform: translate(-50%, -50%) rotate(260deg) translateY(-100px) rotateX(90deg);
	}

	.loading:nth-child(28) {
		transform: translate(-50%, -50%) rotate(270deg) translateY(-100px) rotateX(90deg);
	}

	.loading:nth-child(29) {
		transform: translate(-50%, -50%) rotate(280deg) translateY(-100px) rotateX(90deg);
	}

	.loading:nth-child(30) {
		transform: translate(-50%, -50%) rotate(290deg) translateY(-100px) rotateX(90deg);
	}

	.loading:nth-child(31) {
		transform: translate(-50%, -50%) rotate(300deg) translateY(-100px) rotateX(90deg);
	}

	.loading:nth-child(32) {
		transform: translate(-50%, -50%) rotate(310deg) translateY(-100px) rotateX(90deg);
	}

	.loading:nth-child(33) {
		transform: translate(-50%, -50%) rotate(320deg) translateY(-100px) rotateX(90deg);
	}

	.loading:nth-child(34) {
		transform: translate(-50%, -50%) rotate(330deg) translateY(-100px) rotateX(90deg);
	}

	.loading:nth-child(35) {
		transform: translate(-50%, -50%) rotate(340deg) translateY(-100px) rotateX(90deg);
	}

	.loading:nth-child(36) {
		transform: translate(-50%, -50%) rotate(350deg) translateY(-100px) rotateX(90deg);
	}

	.img_preloader {
		width: 200px;
	}

	#txt_load {
		font-size: 2em;
	}
}
