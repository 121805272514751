.card {
	position: relative;

	.card__link {
		@include coverdiv();
		z-index: 3;
	}
}

.card--default {
	background: #f7f7fc;
    border-radius: 4px;
    padding: rem(32px) rem(48px) rem(50px);
	transition: .3s all;

	@include r($xs-breakpoint) {
		padding: rem(18px) rem(20px) rem(16px);
	}


	&:hover {

		@include rmin($xs-breakpoint) {
			color: #fff;

			.card__subtitle {
				color: #fff;
			}

			.card__bg {
				opacity: 1;
			}
		}
	}

	&.is-active {

		@include r($xs-breakpoint) {
			background-color: #9B51E0;
			color: #fff;


			.card__title {

				&:before {
					background-color: #fff;
				}

				&:after {
					opacity: 0;
				}
			}
		}

		.card__subtitle {
			display: block;
			color: #fff;
			padding-bottom: rem(30px);
		}
	}

	.card__bg {
		@include coverdiv();
		opacity: 0;
		background-color: #9B51E0;
		background-size: cover;
		background-repeat: repeat-x;
		background-position: bottom center;
		transition: .3s all;
	}

	.card__title {
		position: relative;
		z-index: 1;

		@include r($xs-breakpoint) {
			padding-right: rem(45px);
		}

		&:before {
			position: absolute;
			top: 50%;
			right: 1px;
			transform: translateY(-50%);
			width: 18px;
			height: 2px;
			background-color: #0E1750;
			margin-top: -2px;
			border-radius: 50px;

			@include r($xs-breakpoint) {
			    content: '';
			}
		}

		&:after {
			position: absolute;
			top: 50%;
			right: 9px;
			transform: translateY(-50%);
			height: 18px;
			width: 2px;
			background-color: #0E1750;
			margin-top: -2px;
			border-radius: 50px;

			@include r($xs-breakpoint) {
				content: '';
			}
		}
	}

    .card__subtitle {
        margin-top: rem(8px);
	    position: relative;
	    z-index: 2;
	    transition: .3s all;

	    @include r($xs-breakpoint) {
		    display: none;
		    margin-top: rem(20px);
	    }
    }
}
