.fancybox-stage {

	.modal[data-scrollbar] .scroll-content {

		@include r($xs-breakpoint) {
			display: none;
		}
	}

	.modal[data-scrollbar=true] .scroll-content {

		@include r($xs-breakpoint) {
			display: block;
			//opacity: 1 !important;
			//transition: 1s all;
			//transition-delay: 1s;
			//transition-duration: 1s;
		}
	}
}

.fancybox-slide--html {

	&:after {
		@include r($xs-breakpoint) {
			//content: '';
			position: absolute;
			top: 75%;
			bottom: -100%;
			right: 0;
			left: 0;
			background-color: #fff;
		}
	}

	> .fancybox-content {

		@include r($xs-breakpoint) {
			//position: relative;
			//z-index: 2;
		}
	}
}

.modal {
	display: none;
	background-color: transparent;
	padding: 0;

	@include r($xs-breakpoint) {
		display: none;
	}

	.scrollbar-track {
		display: none !important;
	}


	&[data-scrollbar="true"] {
		height: 100%;
		width: 100vw;

		.scroll-content {
			width: 100vw;
			overflow: hidden;
		}
	}

	.scroll-content {
		//display: none;

		@include r($xs-breakpoint) {
			//opacity: 0;
		}
	}

	@include r($xs-breakpoint) {
		&.modal--default {
			padding-top: 0;

			.modal__buttons {

				+ .modal__inner {
					//margin-top: 55vh;
					margin-top: 100vh;
				}
			}

			.modal__inner {
				//margin-top: 50vh;
				margin-top: 100vh;
			}
		}
	}

	&.is-test {
		display: block;
		background: red;


		.modal__inner {
			animation: none !important;
			transform: translateX(0);
		}
	}
}


.fancybox-slide--html {
	padding: 0;
}

.fancybox-active {


	.is-show-menu {

		.header__closeModal {
			opacity: 0 !important;
			visibility: hidden !important;
			transition: none;
		}
	}

	.barBottom {
		z-index: 9999999;

		.barBottom__bg {
			background: #F7F7FC;
			opacity: 0.9;
		}
	}

	.modal--default .modal__inner {
		animation: showSlide 0.5s ease-in forwards;

		@include r($xs-breakpoint) {
			animation: none;
		}
	}

	.modal--default[data-scrollbar="true"] {

		.modal__buttons {

			+ .modal__inner {

				@include r($xs-breakpoint) {
					animation: showSlide4 0.5s linear forwards;
				}
			}
		}

		.modal__inner {
			position: relative;

			@include r($xs-breakpoint) {
				margin-top: 100vh;
				display: block;
				animation: showSlide3 0.5s linear forwards;
				animation-delay: .3s;
			}
		}

		.scroll-content {

			@include r($xs-breakpoint) {
				//display: block;
				//opacity: 0;
				//animation: showSlide2 0.5s linear forwards;
				position: relative;
			}
		}
	}


	.header__closeModal {
		opacity: 1;
		visibility: visible;
		transition: .15s all;
		transition-delay: .4s;
	}
}

.fancybox-bg {
	background-color: transparent;
}

.fancybox-slide--html .fancybox-close-small {
	display: none;
}

.modal--default {
	pointer-events: none;
	width: 100%;

	@include r($xs-breakpoint) {
		padding-top: rem(370px);
		//padding-top: 55vh;
	}

	&:before {
		position: absolute;
		margin-top: rem(415px);
		top: 0;
		left: 0;
		right: 0;
		background-color: #fff;
		bottom: -100%;
		animation: showSlide2 0.5s ease-in forwards;

		@include r($xs-breakpoint) {
			//content: '';
		}
	}

	.modal__buttons {
		position: fixed;
		bottom: rem(99px);
		left: 0;
		width: 50%;
		z-index: 10;
		display: flex;
		justify-content: center;
		align-items: center;
		pointer-events: all;

		@include r($xs-breakpoint) {
			position: absolute;
			bottom: auto;
			//top: rem(250px);
			top: 40vh;
			width: 100%;
		}

		.btn {
			margin: 0 rem(16px);

			@include r($xs-breakpoint) {
				margin: 0 rem(10px);
			}
		}
	}

	.modal__inner {
		width: 50%;
		margin-left: auto;
		background-color: #fff;
		pointer-events: all;
		animation: hideSlide 0.3s ease-out forwards;
		transform: translateX(100%);
		min-height: 100vh;
		padding-top: rem(65px);

		@include r($xs-breakpoint) {
			display: none;
			animation: none;
			transform: none;
			min-height: 100%;
			width: 100%;
			padding-top: 0;
			box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
			border-top-left-radius: 21px;
			border-top-right-radius: 21px;
			margin-top: 100vh;
		}

		&:before {
			position: absolute;
			top: 20px;
			left: 50%;
			transform: translateX(-50%);
			background: #C4C4C4;
			opacity: 0.5;
			border-radius: 5px;
			width: 40px;
			height: 5px;

			@include r($xs-breakpoint) {
				content: '';
			}
		}
	}
}

.modal__head {
	padding: rem(25px) rem(80px);
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	display: none;
}

.modal__body {
	padding: rem(17px) rem(80px) rem(90px);

	@include r($xs-breakpoint) {
		padding: rem(57px) rem(20px);
		width: 100%;
		overflow: hidden;
	}
}

@keyframes showSlide {
	from {
		transform: translateX(100%);
	}

	to {
		transform: translateX(0%);
	}
}

@keyframes showSlide2 {
	from {
		//opacity: 0;
		top: 100%;
	}

	to {
		//opacity: 1;
		top: 0;
	}
}

@keyframes showSlide2 {
	from {
		//opacity: 0;
		top: 100%;
	}

	to {
		//opacity: 1;
		top: 0;
	}
}

@keyframes showSlide3 {
	from {
		opacity: 0;
		margin-top: 100vh;
	}

	to {
		opacity: 1;
		margin-top: 50vh;
	}
}

@keyframes showSlide4 {
	from {
		margin-top: 100vh;
	}

	to {
		margin-top: 55vh;
	}
}

@keyframes hideSlide {
	from {
		transform: translateX(0%);
	}

	to {
		transform: translateX(100%);
	}
}
