//@include font-face('Black', 900);

//@font-face {
//	font-family: 'Gilroy';
//	src: url('../fonts/Gilroy-Black.eot?#iefix') format('embedded-opentype'),
//	url('../fonts/Gilroy-Black.otf')  format('opentype'),
//	url('../fonts/Gilroy-Black.woff') format('woff'),
//	url('../fonts/Gilroy-Black.ttf')  format('truetype'),
//	url('../fonts/Gilroy-Black.svg#Gilroy-Black') format('svg');
//	font-weight: 900;
//	font-style: normal;
//}


@font-face {
	font-family: 'UbuntuCondensed';
	src: url('../fonts/UbuntuCondensed-Regular.eot?#iefix') format('embedded-opentype'),
	url('../fonts/UbuntuCondensed-Regular.woff') format('woff'),
	url('../fonts/UbuntuCondensed-Regular.ttf') format('truetype'),
	url('../fonts/UbuntuCondensed-Regular.svg#UbuntuCondensed-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
}


@font-face {
	font-family: "Roboto";
	src: url("../fonts/Roboto-Bold.eot?#iefix") format("embedded-opentype"),
	url("../fonts/Roboto-Bold.woff") format("woff"),
	url("../fonts/Roboto-Bold.ttf") format("truetype"),
	url("../fonts/Roboto-Bold.svg#Roboto-Bold") format("svg");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Ubuntu";
	src: url("../fonts/Ubuntu-Bold.eot?#iefix") format("embedded-opentype"),
	url("../fonts/Ubuntu-Bold.woff") format("woff"),
	url("../fonts/Ubuntu-Bold.ttf") format("truetype"),
	url("../fonts/Ubuntu-Bold.svg#Ubuntu-Bold") format("svg");
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: "Ubuntu";
	src: url("../fonts/Ubuntu-Regular.eot?#iefix") format("embedded-opentype"),
	url("../fonts/Ubuntu-Regular.woff") format("woff"),
	url("../fonts/Ubuntu-Regular.ttf") format("truetype"),
	url("../fonts/Ubuntu-Regular.svg#Ubuntu-Regular") format("svg");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: "Ubuntu";
	src: url("../fonts/Ubuntu-Light.eot?#iefix") format("embedded-opentype"),
	url("../fonts/Ubuntu-Light.woff") format("woff"),
	url("../fonts/Ubuntu-Light.ttf") format("truetype"),
	url("../fonts/Ubuntu-Light.svg#Ubuntu-Light") format("svg");
	font-weight: 300;
	font-style: normal;
}
