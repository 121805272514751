.barBottom {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	padding: rem(11px) 0 rem(8px);
	text-align: center;
	z-index: 999999;
	//pointer-events: none;


	@include r($xs-breakpoint) {
		padding: rem(5px) 0 rem(2px);
		height: rem(49px);
	}

	.barBottom__bg {
		background-color: #FFDF00;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		transition: .3s ease-in right;
	}

	.barBottom__title {
		font-size: rem(27px);
		line-height: 1;
		text-transform: uppercase;
		text-align: center;
		position: relative;
		font-family: 'UbuntuCondensed';

		@include r($xs-breakpoint) {
			font-size: rem(12px);
		}
	}

	.barBottom__subtitle {
		font-size: rem(14px);
		line-height: 1;
		opacity: 0.6;

		@include r($xs-breakpoint) {
			font-size: rem(9px);
		}
	}
}
