textarea,
input {
	border: 0;
	border-radius: 0;
	background-color: #fff;
	font-family: inherit;
	-webkit-appearance: none !important;
	-moz-appearance: none !important;
	appearance: none !important;
}

textarea {
	resize: none;
}


.textarea,
.input {

}

.input {

	&.input--sm {

	}

	&.input--xs {

	}
}

.textarea {

}
