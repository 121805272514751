.icon-arrow_left {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-arrow_right {
  width: 1.13em;
  height: 1em;
  fill: initial;
}
.icon-back {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-facebook {
  width: 0.53em;
  height: 1em;
  fill: initial;
}
