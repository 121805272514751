button,
.btn {
	position: relative;
	display: inline-flex;
	align-items: center;
	border-radius: 0;
	border: 0;
	transition: 0.3s all;
	background-color: transparent;
	text-align: center;
	padding: 0;
	cursor: pointer;
}

.btn--primary {
	letter-spacing: 0.02em;
	font-size: rem(18px);
	color: #FFFFFF;
	background: #9B51E0;
	border-radius: 4px;
	height: rem(56px);
	min-width: rem(194px);
	padding: 0 rem(15px);
	justify-content: center;

	@include r($xs-breakpoint) {
	    min-width: rem(130px);
		height: rem(40px);
		font-size: rem(14px);
	}


	&:hover {
		color: #fff;
		background-color:#8335CC;
	}
}

.btn--secondary {
	letter-spacing: 0.02em;
	font-size: rem(18px);
	color: $primary-color;
	background-color: #fff;
	border-radius: 4px;
	height: rem(56px);
	min-width: rem(194px);
	padding: 0 rem(15px);
	justify-content: center;
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
	border: 2px solid transparent;


	@include r($xs-breakpoint) {
		min-width: rem(130px);
		height: rem(40px);
		font-size: rem(14px);
	}


	&:hover {
		color: #9B51E0;
		border: 2px solid #9B51E0;
	}
}

.btn--uppercase {
	text-transform: uppercase;
}

.btn--burger {
	display: block;
	padding: 20px;
	margin: -20px;

	.bar {
		display: block;
		width: 18px;
		height: 2px;
		background-color: #0e1750;
		transition: .3s all;
		position: relative;

		&:not(:first-child) {
			margin-top: 6px;
		}
	}
}

.btn--return {
	font-size: rem(14px);
	line-height: 160%;
	letter-spacing: 0.01em;
	color: #6a72a5;

	svg {
		fill: #6a72a5;

		&:not(:last-child) {
			margin-right: rem(7px);
		}
	}


	span {
		position: relative;
		top: 0;
	}
}

.btn--toggleLink {
	font-size: rem(14px);
	line-height: rem(22px);
	color: #9B51E0;

	&.is-active {
		color: #ffdf00;

		&:after {
			background-image: url('../img/arrow_top.svg');
		}

		.default {
			display: none;
		}

		.active {
			display: block;
		}
	}


	&:after {
		content: '';
		display: block;
		margin-left: 6px;
		background-image: url('../img/arrow_bottom.svg');
		width: 10px;
		height: 6px;
		@include cover();
	}

	.default {
		display: block;
	}

	.active {
		display: none;
	}
}


.btn--cardArrow {
	position: absolute;
	top: 0;
	right: 0;
	padding-top: rem(33px);
	padding-right: rem(33px);


	&:before {
		content: '';
		width: 0;
		height: 0;
		transition: .4s all cubic-bezier(0.175, 0.885, 0.32, 1.275);
		background-color: #8F40DA;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		border-radius: 100%;
		margin-top: rem(15px);
		margin-left: rem(-10px);
	}

	svg {
		fill: #fff;
		position: relative;
		z-index: 1;
		transform: translateX(-5px);
		opacity: 0;
		transition: .3s all;
	}
}


.btn--status {
	color: #6A72A5;
	background: #FFFFFF;
	border-radius: 23px;
	width: rem(93px);
	height: rem(33px);
	justify-content: center;
	font-size: rem(14px);

	&.is-active {
		background-color: #9B51E0;
		color: #fff;
	}
}


.btn--radius {
	background: #F7F7FC;
	border: 1px solid rgba(106, 114, 165, 0.1);
	border-radius: 23px;
	height: rem(33px);
	color: #0E1750;
	font-size: rem(14px);
	padding: 0 rem(16px);

	&.is-active {
		background-color: #9B51E0;
		color: #fff;
	}
}
