.h1 + .messageBox {
	margin-top: rem(49px);

	@include r($xs-breakpoint) {
		margin-top: rem(35px);
	}
}

.messageBox {
	&__item {


        &--left {
            margin-bottom: rem(34px);
            padding-right: rem(47px);

	        @include r($xs-breakpoint) {
		        padding-right: 0;
	        }

			.messageBox__text {
                display: inline-flex;
                align-items: center;
                text-align: left;
                font-size: rem(14px);
                background: #F7F7FC;
				line-height: rem(22px);
                color: #6A72A5;
                min-height: rem(70px);
                border-bottom-right-radius: 0;
                padding: rem(24px) rem(48px) rem(32px);

				&:before {
					content: '';
					min-height:inherit;
					font-size: 0;
				}

				@include r($xs-breakpoint) {
					line-height: rem(20px);
					padding: rem(10px) rem(20px);
				}
			}
		}

		&--right {
            text-align: right;
            margin-bottom: rem(16px);

			@include r($xs-breakpoint) {
				margin-bottom: rem(14px);
			}

            &:not(:first-child) {
                margin-top: rem(34px);
            }

			.messageBox__text {
                display: inline-flex;
                align-items: center;
                text-align: left;
				background: #9b51e0;
				box-shadow: 0px 18px 50px rgba(0, 0, 0, 0.1);
				font-size: rem(14px);
				line-height: rem(22px);
                color: #fff;
                min-height: rem(70px);
                border-bottom-right-radius: 0;
                padding: 0 rem(55px) 0 rem(50px);

				&:before {
					content: '';
					min-height:inherit;
					font-size: 0;
				}

				@include r($xs-breakpoint) {
					min-height: rem(62px);
					line-height: rem(20px);
					padding: 0 rem(20px);
				}
			}
		}
    }

    &__text {
        border-radius: rem(22px);
    }
}
