.is-show-menu {

	.btn--burger .bar {

		&:nth-child(1) {
			transform: translate(0, 1px) rotate(45deg);
		}
		&:nth-child(2) {
			transform: translate(0px, -7px) rotate(-45deg);
		}
	}
}

.header {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: 999999;
	pointer-events: none;

	@include r($xs-breakpoint) {
		padding: 26px 0;
	}


	.header__burger {
		opacity: 0;
		visibility: hidden;
		transition: .3s all;

		&.is-show {
			opacity: 1;
			visibility: visible;
		}
	}

	.header__inner {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 73px;

		@include r($xs-breakpoint) {
			height: auto;
		}

		> * {
			pointer-events: all;
		}
	}

	.logo svg {

		@include r($xs-breakpoint) {
			width: 101px;
			height: 16px;
		}
	}

	.header__closeModal {
		//padding-left: 85px;
		margin-left: -22px;
		position: relative;
		top: 2px;
		opacity: 0;
		visibility: hidden;
		transition: none;

		@include r($xs-breakpoint) {
			display: none;
		}
	}
}
