.text--md  + .contactsBox {
    margin-top: rem(57px);

    @include r($xs-breakpoint) {
        margin-top: rem(40px);
    }
}

.contactsBox {


    &Company {

        &__title {
            margin-top: rem(24px);
            @extend .h2;
        }
    }


    &__info {
        margin-top: rem(21px);
        margin-left: rem(-3px);

        @include r($xs-breakpoint) {
            margin-top: rem(18px);
        }

        table {
            width: 100%;

            @include r($xs-breakpoint) {
                display: block;
            }

            tr {
                @include r($xs-breakpoint) {
                    display: block;
                }

                &:not(:first-child) {

                    @include r($xs-breakpoint) {
                        margin-top: rem(18px);
                    }
                }
            }

            td {
                padding-bottom: rem(18px);
                vertical-align: top;

                @include r($xs-breakpoint) {
                    display: block;
                    padding-bottom: 0;
                }

                p:not(:first-child) {
                    margin-top: rem(21px);

                    @include r($xs-breakpoint) {
                        margin: 0;
                    }
                }

                a {

                    @include r($xs-breakpoint) {
                        display: block;
                    }
                }
            }

            td:first-child {
                width: percentage(126/516);

                @include r($xs-breakpoint) {
                    width: 100%;
                }
            }

            a {
                color: $secondary-color;
            }
        }
    }
}
