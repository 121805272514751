.menuBox {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: radial-gradient(50% 66% at 50% 50%, #AF63F6 0%, #8335CC 100%);
	opacity: 0;
	visibility: hidden;
	transition: .3s all;
	z-index: 99999;

	&:before {
		content: '';
		@include coverdiv();
		@include cover();
		background-attachment: fixed;
		background-image: url('../img/lines_menu.svg');
	}

	&.is-active {
		opacity: 1;
		visibility: visible;
	}

	.menuBox__inner {
		@include coverdiv();
		overflow-y: auto;
		overflow-x: hidden;

		@include r($xs-breakpoint) {
		    overflow: hidden;
		}
	}

	.menu {
		font-weight: 700;
		font-size: rem(89px);
		line-height: 1;
		text-align: center;
		letter-spacing: 0.02em;
		padding: rem(87px) 0 rem(155px);

		@include r($xs-breakpoint) {
			font-size: rem(18px);
			line-height: 1;
			padding: 25% 0;
			display: flex;
			flex-flow: column;
			justify-content: space-between;
			height: 100%;
		}

		li:not(:first-child) {
			margin-top: rem(103px);

			@include r($xs-breakpoint) {
				margin-top: rem(17px);
			}
		}


		a {
			//color: transparent;
			//-webkit-text-stroke: 2px rgb(255, 255, 255);
			position: relative;
			display: inline-block;

			color: #fff;
			-webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
			-webkit-text-stroke-width: 2px;
			-webkit-text-stroke-color: #fff;

			@include r($xs-breakpoint) {
				color: #fff;
				-webkit-text-stroke: 0 rgb(255, 255, 255);
				-webkit-text-fill-color: #fff;
			}

			&:hover {

				@include rmin($xs-breakpoint) {
					color: #fff;
					-webkit-text-fill-color: #fff;

					img {
						opacity: 1;
						transform: translateY(-50%) scale(1);
					}
				}
			}


			span {
				display: block;
			}

			&[data-id="#mapModal"] img {
				right: rem(-230px);
				left: auto;
				margin-top: rem(-68px);
			}

			img {
				position: absolute;
				top: 50%;
				left: 0;
				transform: translateY(-50%) scale(0);
				opacity: 0;
				transition: .4s all cubic-bezier(0.645, 0.045, 0.355, 1);
				pointer-events: none;
				z-index: 1;

				&[alt="Где купить?"] {
					width: rem(258px);
					height: rem(256px);
				}

				&.klinzit_n {
					left: 100%;
					margin-left: rem(-48px);
					margin-top: rem(-36px);
					min-width: rem(377px);
					height: rem(308px);
				}

				&.vopros_fur {
					left: 0;
					margin-left: rem(-179px);
					margin-top: rem(-40px);
					min-width: rem(269px);
					height: rem(377px);
				}

				&.break-up {
					transform: none;
					top: 100%;
					margin-top: rem(-297px);
					left: rem(49px);
					margin-left: rem(-90px);
					min-width: rem(410px);
					height: rem(253px);
				}

				&.virus {
					left: 100%;
					margin-left: rem(-17px);
					min-width: rem(363px);
					height: rem(386px);
				}

				&.klensit-c_alpha {
					left: auto;
					right: 100%;
					margin-top: rem(65px);
					margin-right: rem(-75px);
					width: rem(454px);
					height: rem(362px);
				}

				&.skincare_img-New2 {
					width: rem(273px);
					height: rem(397px);
					//left: 50%;
					left: auto;
					right: 100%;
					//margin-left: rem(-170px);
					margin-right: rem(-37px);
					margin-top: rem(10px);
				}

				&.donut {
					width: rem(332px);
					height: rem(317px);
					left: auto;
					right: 100%;
					margin-top: rem(-106px);
					margin-right: rem(-60px);
				}

				&.smile {
					width: rem(282px);
					height: rem(257px);
					left: 100%;
					margin-left: rem(-21px);
					margin-top: rem(30px);
				}

				&.apteka_alpha {
					width: rem(352px);
					height: rem(352px);
					left: 100%;
					margin-left: rem(-85px);
					margin-top: rem(-85px);
				}

				&.pin {
					left: auto;
					right: 100%;
					width: rem(239px);
					height: rem(355px);
					margin-right: rem(-31px);
					margin-top: rem(-80px);
				}
			}
		}
	}
}

.is-show-menu {

	.header {

		.logo svg path {
			fill: #fff;
		}
	}

	.btn--burger .bar {
		background-color: #fff;
	}


	.barBottom {
		color: #fff;

		.barBottom__bg {
			background-color: #9B51E0;
		}
	}
}

.menuCont {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	z-index: 2;
	justify-content: center;
	align-items: center;
	overflow: auto;
	user-select: none;
	/* perspective: 1000px; */
}

.menuLine {
	position: relative;
	display: flex;
	height: auto;
	line-height: 1;
	justify-content: center;
	margin: 0;
	padding: 0;
	list-style: none;
	font-size: 5em;
	color: #b062f000;
	-webkit-text-stroke: 2px rgb(255, 255, 255);
	transform-style: preserve-3d;
	perspective: 2500px;
}

.menuLineCharacter {
	top: 50%;
	left: 50%;
	transform-style: preserve-3d;
}

.menuLine:hover > div {
	color: #ffffff;
}

