:root {
	--browser-address-bar: 0px;
}

html {
	font-family: $font-main;
	-webkit-tap-highlight-color: transparent;
	@include font-smoothing();
	color: $text-color;
	-webkit-overflow-scrolling: touch;
}

.out,
.pageWrapper,
html,
body {
	// background-color: #F1F8FD;
	height: 100%;
}

html, body {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	height: 100% !important;
	overflow: hidden;
}

[class*="ymaps-2"][class*="-ground-pane"] {
	filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
	/* Firefox 3.5+ */
	-webkit-filter: grayscale(100%);
	/* Chrome 19+ & Safari 6+ */
}

* {
	outline: none;
	box-sizing: border-box;
}

ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

a {
	transition: 0.25s all;
	text-decoration: none;
	color: $black;

	&:hover {
		color: $primary-color;
	}

	&:active {
		opacity: 0.8;
	}
}

img {
	max-width: 100%;
	height: auto;
	vertical-align: middle;
}

input:focus {
	outline: none;
}

::selection {
	color: $white;
	background-color: $primary-color;
}

::-moz-selection {
	color: $white;
	background-color: $primary-color;
}

:root {
	--browser-address-bar: 0px;
	--browser-bar: 0px;
}

.out {
	position: relative;
	overflow: hidden;
}

.pageWrapper {
	position: relative;
	//min-height: 100vh;
}


.no-scroll {
	overflow: hidden;
}

.is-fixed {
	position: fixed;
	top: 0;
	left: 0;
}

#container {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	z-index: 0;
}

#panel {
	position: absolute;
	background-color: rgb(113, 160, 199);
	z-index: 1;
}

.desktop {
	top: 0px;
	left: 100%;
	width: 50%;
	height: 100%;
}

.mobile {
	top: 100%;
	left: 0px;
	width: 100%;
	height: 100%;
	border-radius: 20px 20px 0px 0px;
}

#close {
	position: absolute;
	top: 0px;
	right: 0px;
	visibility: hidden;
}

.colorPrimary {
	color: $primary-color;
}

.colorPrimaryLight {
	color: $primary-color-light;
}

.downloadRoboto {
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	visibility: hidden;
	font-family: 'Roboto';
}


.show-360 {
	&:before {
		content:'' ;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		pointer-events: none;
		background-image: url('../img/360.svg');
		background-repeat: no-repeat;
		background-position: center center;
		z-index: 100;
	}
}
