.cookieBox {
	position: fixed;
	bottom: rem(78px);
	right: rem(66px);
	z-index: 100000;
	background: rgba(255, 255, 255, 0.83);
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
	border-radius: 28px;
	width: rem(343px);
	opacity: 0;
	visibility: hidden;
	transition: .3s opacity ease-in-out;


	@include r($xs-breakpoint) {
		right: auto;
		left: 50%;
		transform: translateX(-50%);
	}

	&.is-show {
		opacity: 1;
		visibility: visible;
	}

	&.is-active {

		.cookieBox__head {
			display: none;
		}

		.cookieBox__body {
			display: block;
		}
	}


	.cookieBox__close {
		position: absolute;
		padding: rem(16px);
		top: 0;
		right: 0;
		z-index: 5;

		&:hover {

			svg {
				fill: $primary-color;
			}
		}


		svg {
			transition: .3s all;
		}
	}

	.cookieBox__head {
		display: flex;
		align-items: center;
	}

	.cookieBox__preview {
		color: #0E1750;
		font-size: rem(16px);
		position: relative;
		padding: 0 rem(50px) 0 rem(60px);
		height: rem(52px);
		display: flex;
		align-items: center;
		letter-spacing: 0;
		white-space: nowrap;


		&:before {
			content: '';
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: rem(12px);
			margin-top: rem(-1px);
			width: rem(34px);
			height: rem(37px);
			background-image: url('../img/cookie.svg');
			background-position: center center;
			background-size: contain;
			background-repeat: no-repeat;
		}

		span {
			color: #9B51E0;
		}
	}

	.cookieBox__body {
		display: none;
		font-size: rem(14px);
		line-height: rem(20px);
		padding: rem(24px) rem(30px) rem(26px) rem(20px);
	}

	.cookieBox__title {
		font-size: rem(16px);
		line-height: rem(26px);
		margin-bottom: rem(10px);
	}

	.cookieBox__link {
		font-size: rem(16px);
		line-height: rem(26px);
		color: #9B51E0;
		margin-top: rem(12px);
		display: block;
	}
}
