.card--product {
	background: #F7F7FC;
	border-radius: 4px;
	overflow: hidden;
	padding: rem(42px) rem(27px) rem(220px);


	&.card--lg {

		@include rmin($xs-breakpoint) {
			overflow: visible;
			padding: rem(25px) rem(27px) rem(23px);
			min-height: rem(183px);

			.card__photo {
				bottom: 0;
				right: rem(60px);
			}

			.card__category {
				margin-bottom: rem(38px);
			}
		}

		@include r($xs-breakpoint) {

			.card__photo {
				bottom: 0;
				width: 100%;
				text-align: center;
			}
		}
	}

	.card__category {
		font-size: rem(14px);
		line-height: 160%;
		color: #6A72A5;
		margin-bottom: rem(18px);

		@include r($xs-breakpoint) {
			margin-bottom: 35px;
		}
	}

	.card__title {
		font-size: rem(16px);
		line-height: rem(26px);

		@include r($xs-breakpoint) {
			color: $primary-color;
		}
	}

	.card__photo {
		position: absolute;
		bottom: rem(22px);
		right: 0;
	}
}
