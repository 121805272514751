.card--different {
    border-radius: 4px;
    overflow: hidden;
    background-color: #F7F7FC;
    transition: .3s all;

    &.is-active {
        background-color: #9B51E0;
        color: rgba(#fff, .7);

        .card__title {
            color: #fff;
        }

        .card__photo {
            height: rem(250px);

            @include r($xs-breakpoint) {
                height: rem(200px);
            }
        }

        .card__body {
            padding-bottom: rem(17px);

            @include r($xs-breakpoint) {
                padding-bottom: rem(14px);
            }
        }
    }


    .card__title {
        font-size: rem(18px);
        margin-bottom: rem(16px);
        color: #0E1750;
        transition: .3s all;

        @include r($xs-breakpoint) {
            margin-bottom: rem(9px);
        }
    }

    .card__subtitle {
        margin-top: rem(16px);
        font-size: rem(14px);
        line-height: rem(22px);

        @include r($xs-breakpoint) {
            margin-top: 0;
            line-height: rem(20px);
        }
    }

    .card__photo {
        @include cover();
        height: 0;
        transition: .3s all;
    }

    .card__body {
        padding: rem(30px) rem(48px) rem(40px);

        @include r($xs-breakpoint) {
            padding: rem(20px);
        }
    }

    .card__toggle {
        margin-top: rem(14px);
        padding-left: rem(2px);

        @include r($xs-breakpoint) {
            margin-top: rem(27px);
        }
    }
}
