.select2.select2-container.select2-container--default {
	width: auto !important;
}

.select2-container--default {

	.select2-selection--single {
		background: #F7F7FC;
		border: 1px solid rgba(106, 114, 165, 0.1);
		box-sizing: border-box;
		border-radius: 23px !important;
		height: rem(33px);

		.select2-selection__rendered {
			color: #0E1750;
			font-size: rem(14px);
			height: rem(33px);
			line-height: rem(33px);
			padding: 0 rem(44px) 0 rem(16px);
		}

		.select2-selection__arrow {
			background-image: url('../img/select_ar_b.svg');
			background-repeat: no-repeat;
			width: rem(35px);
			background-size: rem(10px) rem(7px);
			background-position: center center;
			height: 100%;

			b {
				display: none;
			}
		}

	}

	.select2-search--dropdown .select2-search__field {
		background: #F7F7FC;
		border: 1px solid rgba(106, 114, 165, 0.1);
		border-radius: 23px;
		height: rem(33px);
		padding: 0 rem(12px);
	}
}

.select2-container--open {


	.select2-dropdown--below {
		border: 0;
		background: #FFFFFF;
		box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
		border-radius: 10px;
		min-width: rem(269px);
		margin-top: rem(20px);
		padding: rem(18px) rem(16px);
		z-index: 111111;

		@include r($xs-breakpoint) {
			margin-left: rem(-22px);
			border-radius: 0;
			min-width: 100vw;
			margin-top: rem(18px);
			box-shadow: none;
			padding-bottom: rem(20px);
			//height: calc(100vh - 3.0625rem - 9.3125rem - 10.5625rem - var(--browser-address-bar));
			height: calc(100vh - 6.125rem - 1.125rem - 3.125rem - 3.0625rem - var(--browser-address-bar));
			overflow: hidden;
		}
	}

	.select2-results__options {
		padding: rem(5px) 0 rem(20px);
	}

	.select2-results__option {
		font-size: rem(14px);
		color: #0E1750;
		padding: 0;

		&:not(:first-child) {
			margin-top: rem(4px);
		}
	}

	.select2-search__field {
		font-size: rem(14px);
	}

	.select2-search__field::placeholder {
		color: rgba(#6A72A5, .5);
		font-size: rem(14px);
	}

	.select2-search--dropdown {
		padding: rem(4px) 0;

		@include r($xs-breakpoint) {
			padding-bottom: rem(20px);
		}
	}
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
	background-color: transparent;
	color: #6A72A5;
}

.select2-container--default .select2-results__option[aria-selected=true] {
	background-color: transparent;
	color: #6A72A5;
}

.select2-container--default .select2-results>.select2-results__options {
	max-height: rem(400px);

	@include r($xs-breakpoint) {
		max-height: rem(330px);
	}
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single {
	background-color: #9B51E0;
	color: #fff;


	.select2-selection__rendered {
		color: #fff;
	}

	.select2-selection__arrow {
		background-image: url('../img/select_ar_t.svg');
		background-position: center 45%;
	}
}


.select2-container.select2-container--default.select2-container--open {
	height: 100%;
}
