#cursor {
	position: absolute;
	z-index: 111111111111;
	margin-top: -23px;
	margin-left: -33px;
	pointer-events: none;

	@include r($xs-breakpoint) {
		display: none;
	}

	&.is-hidden {

		@include rmin($xs-breakpoint) {
			display: none;
		}
	}
}

.cursor {


	&__item {
		display: none;

		&.is-active {
			display: block;
		}
	}
}
