.modal--map {
	padding: rem(107px) rem(64px) 0;
	background-color: gray;
	width: 100%;
	height: 100vh;

	@include r($xs-breakpoint) {
		height: calc(100vh - var(--browser-address-bar));
	}

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: rem(117px);
		background: linear-gradient(180deg, #F7F7F7 0%, rgba(247, 247, 247, 0) 100%);
		z-index: 11;
		pointer-events: none;
	}

	.modal__inner {
		margin-left: auto;
		background-color: #fff;
		box-shadow: 0px 4px 104px rgba(0, 0, 0, 0.05);
		width: rem(411px);
		border-radius: 10px;
		overflow: hidden;
		position: relative;
		z-index: 2;
		height: calc(100vh - 6.6875rem);

		@include r($xs-breakpoint) {
			width: 100vw;
			height: calc(100vh - var(--browser-address-bar));
		}
	}
}


.form--mapSearch {
	position: relative;

	.input {
		width: 100%;
		padding: 0 rem(70px) 0 rem(24px);
		font-size: rem(14px);
		height: rem(64px);

		&::placeholder {
			color: #6A72A5;
			opacity: 0.5;
		}
	}


	.btn {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		padding: 0 rem(27px);
	}
}


.searchBox__filter {
	background: #F7F7FC;
	padding: rem(16px) rem(25px) rem(8px);
	display: flex;
	flex-flow: wrap;
	margin: 0 -6px;

	> div {
		margin: 0 6px;
	}
}

.card--place {
	padding: rem(23px) rem(22px);

	.card__title {
		font-size: rem(18px);
	}

	.card__subtitle {
		font-size: rem(14px);
	}

	.card__description {
		margin-top: rem(23px);
		color: #6A72A5;
		font-size: rem(14px);
		line-height: rem(20px);


		li {
			position: relative;
			padding-left: rem(19px);

			&:not(:first-child) {
				margin-top: rem(7px);
			}
		}

		.marker {
			position: absolute;
			top: rem(6px);
			left: 0;
			width: rem(10px);
			height: rem(10px);
			border-radius: 100%;
		}
	}
}

.searchBox__list {

	.searchBox__item {

		&:not(:first-child) {
			border-top: 1px solid #F7F7FC;
		}
	}

}

.mapMain {
	@include coverdiv();
}


.mapControls {
	position: absolute;
	bottom: rem(134px);
	left: rem(68px);

	@include r($xs-breakpoint) {
		bottom: rem(49px);
		left: auto;
		right: 20px;
		display: flex;
		flex-flow: row-reverse;
		align-items: center;
		z-index: 9999;
		padding-bottom: rem(7px);
	}


}

.map__toggleState {
	display: none;

	@include r($xs-breakpoint) {
		display: block;
		position: absolute;
		bottom: rem(49px);
		left: 0;
		right: 0;
		z-index: 1111;
		background-color: #F7F7FC;
		padding: rem(7px) 20px;
	}

	.btn {

		@include r($xs-breakpoint) {
			height: rem(35px);
			width: rem(200px);
			box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.06);
			border-radius: 3px;
		}
	}

	//.btn span:not(:first-child) {
	//	@include vcenter();
	//}

	span {
		display: block;
	}

	.mapState {
		//opacity: 0;
		//visibility: hidden;
		display: none;
	}

}

.mapControlsZoom {
	height: rem(68px);
	width: rem(35px);
	background: #FFFFFF;
	box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.06);
	border-radius: 23px;
	overflow: hidden;
	text-align: center;

	@include r($xs-breakpoint) {
		display: flex;
		align-items: center;
		flex-flow: row-reverse;
		width: rem(68px);
		height: rem(35px);
	}


	&__control {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 50%;

		@include r($xs-breakpoint) {
			height: auto;
			width: 50%;
		}

		svg {

			path {
				stroke: rgba(#6A72A5, .5);
			}
		}
	}
}


.mapControlsGeo {
	margin-top: rem(8px);

	@include r($xs-breakpoint) {
		margin-top: 0;
		margin-right: rem(8px);
	}
}

.btn--geolocation {
	background: #FFFFFF;
	box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.06);
	border-radius: 23px;
	width: rem(35px);
	height: rem(35px);
	justify-content: center;

	@include r($xs-breakpoint) {

	}

	svg {
		top: rem(-2px);
		position: relative;
	}
}


.is-show-map {

	.header__closeModal {
		padding-left: 0;
		margin-left: auto;
		margin-right: rem(270px);

		svg circle {
			opacity: 0;
		}
	}
}

.is-active-state {

	.modal--map {

		.mapControls {
			//display: none;
		}

		.ymaps-2-1-74-controls__control_toolbar {
			display: block;
		}

		.ymaps-2-1-74-islets_serp-popup.ymaps-2-1-74-islets__hidden {
			display: block !important;
		}

		.ymaps-2-1-74-balloon-panel {
			visibility: hidden;
		}
	}

	.js-toggle-stateMap {

		.mapState {
			//opacity: 1;
			//visibility: visible;
			display: block;
		}

		.listState {
			//opacity: 0;
			//visibility: hidden;
			display: none;
		}
	}

	.modal--map .mapBody {

		@include r($xs-breakpoint) {
			display: block;
		}

		&.is-active {

			@include r($xs-breakpoint) {
				display: block;
			}
		}
	}
}

.modal--map {

	.ymaps-2-1-74-copyrights-pane {
		display: none !important;
	}

	.modal__inner,
	.searchBox {
		display: none;
	}

	.mapBody {
		position: absolute;
		top: rem(73px);
		right: 0;
		z-index: 111;
		width: rem(411px);
		padding: 0 rem(16px);
		display: none;

		@include r($xs-breakpoint) {
			top: rem(65px);
		}

		&.is-active {
			display: block;

			@include r($xs-breakpoint) {
				display: none;
			}
		}

		@include r($xs-breakpoint) {
			width: 100%;
			display: none;
		}

		.searchBox__filter {
			background-color: transparent;
			margin: 0;
			padding: 0;
		}
	}

	.ymaps-2-1-74-map * {
		box-sizing: border-box !important;
		font-family: $font-main !important;
	}

	.ymaps-2-1-74-controls__control_toolbar {
		margin-top: 0 !important;
		//margin-right: 100px !important;

		@include r($xs-breakpoint) {
			display: none;
		}
	}

	.ymaps-2-1-74-svg-icon,
	.ymaps_https___api_maps_yandex_ru_2_1_74_463476569843islands_circleDotIcon___1E98FF__1E98FF_27x27_1571256597844 {
		background-image: url('../img/marker.svg');
		background-size: rem(49px) rem(49px);
		width: rem(49px) !important;
		height: rem(49px) !important;
		left: rem(-25px) !important;
		top: rem(-25px) !important;
	}

	.ymaps-2-1-74-search > ymaps:first-child {
		display: none;
	}

	.ymaps-2-1-74-islets_serp-popup__tail {
		display: none;
	}

	.ymaps-2-1-74-islets_serp-popup {
		display: block;
		margin-top: 0;
		box-shadow: none;
		border: 0;
		padding: 0;
		width: rem(411px);

		@include r($xs-breakpoint) {
			width: 100vw;
		}

		&.ymaps-2-1-74-islets__hidden {
			display: block !important;
		}
	}

	.ymaps-2-1-74-controls__toolbar {
		margin-top: 0 !important;
	}

	.ymaps-2-1-74-controls__control_toolbar {
		padding-top: rem(116px);
		margin-right: 0 !important;
		background-color: #fff;
		width: rem(411px);
		height: 100vh;

		@include r($xs-breakpoint) {
			padding-top: rem(116px);
			width: 100vw;
			height: calc(100vh - var(--browser-address-bar) - 3.125rem - 3.0625rem);
		}
	}

	.ymaps-2-1-74-islets_serp {

		&:before {
			content: '';
			position: absolute;
			top: 100%;
			left: 0;
			bottom: -100%;
			right: 0;
			background-color: #fff;
		}

		@include r($xs-breakpoint) {
			max-height: calc(100vh - var(--browser-address-bar) - 116px) !important;
			padding-bottom: rem(150px);
		}
	}

	.ymaps-2-1-74-islets_serp-popup {
		margin-top: 0;
		box-shadow: none;
		padding: 0;
		//width: rem(411px);
	}

	.ymaps-2-1-74-searchbox__normal-layout {
		width: rem(411px);

		@include r($xs-breakpoint) {
			width: 100vw;
		}
	}

	.ymaps-2-1-74-islets_serp {
		overflow-x: hidden !important;
		//width: rem(411px);
		//max-height: 651px;
	}

	.ymaps-2-1-74-islets_serp-item {
		border-top: 1px solid #F7F7FC !important;
		padding: rem(15px) rem(10px) rem(8px) rem(22px);
		//width: rem(411px);

		@include r($xs-breakpoint) {
			padding: rem(15px) rem(20px) rem(22px);
		}

		.ymaps-2-1-74-islets_serp-item__title {
			font-size: rem(18px);
			color: #0E1750;
		}

		.ymaps-2-1-74-islets_card__description {
			color: #6A72A5;
			font-size: rem(14px);
			margin-top: rem(7px);
			margin-bottom: rem(12px);
		}


	}

	.ymaps-2-1-74-organization-rating__note {
		color: #0E1750;
		font-size: rem(14px);
		margin-left: 1px;
	}

	.ymaps-2-1-74-organization-rating__star {
		width: rem(15px);
		height: rem(14px);
		background-position: center center;
		background-size: rem(15px) rem(14px);
		background-image: url('../img/star/def.svg') !important;
		position: relative;
		top: -1px;

		&:not(:first-child) {
			margin-left: rem(3.5px);
		}

		&:after {
			content: none;
		}


		&.ymaps-2-1-74-organization-rating__star_full {
			background-image: url('../img/star/fill.svg') !important;
			background-size: rem(15px) rem(16px);
			position: relative;
			top: -1px;
		}

		&.ymaps-2-1-74-organization-rating__star_half {
			width: rem(16px);
			height: rem(15px);
			background-image: url('../img/star/half.svg') !important;
		}
	}

	.ymaps-2-1-74-islets_card__status-time-text-full {
		color: #6A72A5;
		font-size: rem(14px);
	}

	.ymaps-2-1-74-islets_card__status-time-icon {
		background: url('../img/time.svg') no-repeat;
		background-size: contain;
		background-position: center center;
		width: rem(15px);
		height: rem(15px);
		margin-right: rem(6px);
		position: relative;
		top: rem(2px);
	}

	.ymaps-2-1-74-islets_serp-item.ymaps-2-1-74-islets__selected,
	.ymaps-2-1-74-islets_serp-item.ymaps-2-1-74-islets__selected:hover, .ymaps-2-1-74-islets_serp-item:hover {
		background: #F7F7FC;
		border-color: #F7F7FC !important;
	}

	.ymaps-2-1-74-islets_serp__loadmore {
		border-top: 1px solid #F7F7FC;
		color: #9B51E0;
		padding: rem(14px) 0;
		line-height: 1;

		&:hover {
			border-color: #F7F7FC !important;
			background-color: #F7F7FC;
		}
	}


	.ymaps-2-1-74-balloon {
		box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
		padding: 0;
	}


	.ymaps-2-1-74-balloon__layout {
		width: rem(334px);

		@include r($xs-breakpoint) {
			width: 100vw;
		}

		#id_157123698124335231532 {
			height: auto !important;
		}

		.ymaps-2-1-74-islets_card__separator {
			margin-left: rem(-22px);
			margin-right: rem(-20px);
		}
	}

	.ymaps-2-1-74-balloon__close-button {
		width: rem(55px) !important;
		height: rem(40px) !important;
		bottom: rem(-7px) !important;
		position: relative;

		@include r($xs-breakpoint) {
			width: rem(50px) !important;
			height: rem(40px) !important;
			position: relative;
			top: rem(10px) !important;
			bottom: auto !important;
		}
	}

	.ymaps-2-1-74-balloon__close + .ymaps-2-1-74-balloon__content {
		margin-right: 0;
	}

	.ymaps-2-1-74-balloon__content {
		//padding: 16px 18px 16px 22px;
		padding: 0;

		> ymaps {
			width: 100% !important;

			@include r($xs-breakpoint) {
				height: auto !important;
			}
		}

		.ymaps-2-1-74-islets_card {
			padding: rem(17px) rem(22px) rem(16px) rem(24px);
			width: rem(334px);

			@include r($xs-breakpoint) {
				width: 100vw;
				padding: rem(20px) rem(20px) rem(120px);
			}
		}
	}


	.ymaps-2-1-74-islets_card__title-business-link {
		color: #0E1750 !important;
		font-size: rem(18px);
	}

	.ymaps-2-1-74-islets_card__description {
		font-size: rem(14px);
		color: #6A72A5;
		margin-top: rem(5px);
		margin-bottom: rem(14px);
	}

	.ymaps-2-1-74-islets_card__status-time-text {

		&:after,
		&:before {
			content: none !important;
		}
	}

	.ymaps-2-1-74-islets_card__separator {
		background-color: #F7F7FC;
	}

	.ymaps-2-1-74-islets_card__contacts-phones-item {
		padding-left: rem(28px);
	}

	.ymaps-2-1-74-islets_card__contacts-phones-item-text {
		font-size: rem(14px);
		color: #0E1750;
	}

	.ymaps-2-1-74-islets_card__contacts-phones-item:first-child:before {
		width: rem(15px);
		height: rem(15px);
		background-image: url('../img/phone.svg');
		background-position: center;
		top: rem(4px);
	}

	.ymaps-2-1-74-islets_card__contacts-urls {
		margin-top: rem(3px);
		padding-left: rem(29px);
	}

	.ymaps-2-1-74-islets_card__contacts-url:link, .ymaps-2-1-74-islets_card__contacts-url:visited,
	.ymaps-2-1-74-islets_card__contacts-url {
		color: #0E1750;
	}

	.ymaps-2-1-74-islets_card__contacts-urls:before {
		background-image: url('../img/globe.svg');
		width: rem(15px);
		height: rem(15px);
		top: rem(5px);
	}

	.ymaps-2-1-74-islets_card__metro {
		margin-top: rem(13px);
	}

	.ymaps-2-1-74-islets_card__metro-station {
		font-size: rem(14px);
	}

	.ymaps-2-1-74-islets_card__metro-station-icon:before {

	}

	.ymaps-2-1-74-islets_card.ymaps-2-1-74-islets__moscow .ymaps-2-1-74-islets_card__metro-station-icon, .ymaps-2-1-74-islets_card.ymaps-2-1-74-islets__spb .ymaps-2-1-74-islets_card__metro-station-icon {
		margin-right: rem(12px);
	}

	.ymaps-2-1-74-islets_card__metro-station {
		margin-top: rem(6px);
	}

	.ymaps-2-1-74-islets_card__metro-station-distance-link-hint {
		color: #6A72A5;
	}

	.ymaps-2-1-74-islets_card__route-button {
		height: rem(35px);
	}

	.ymaps-2-1-74-islets_card__route-button-text {
		line-height: rem(34px);
		font-size: rem(14px);
	}

	.ymaps-2-1-74-islets_card__business-buttons, .ymaps-2-1-74-islets_card__toponym-buttons {
		margin-top: rem(18px);
		height: auto;
	}

	.ymaps-2-1-74-islets_card__business-button {
		height: rem(35px);
		background-color: #9B51E0;

		&:hover {
			color: #fff;
			background-color: #8335CC;
		}
	}

	.ymaps-2-1-74-islets_card__business-button-text {
		color: #fff;
		line-height: rem(33px);
	}

	.ymaps-2-1-74-islets_card__buttons-cell:first-of-type {
		padding-right: rem(8px);
	}

	.ymaps-2-1-74-islets_card__buttons-cell + .ymaps-2-1-74-islets_card__buttons-cell {
		padding-left: rem(8px);
	}


	.ymaps-2-1-74-islets_card__route-button {
		border-color: rgba(#6A72A5, .5);
	}

	.ymaps-2-1-74-islets_card__taxi-link:before, .ymaps-2-1-74-islets_card__taxi:before {
		content: none;
	}

	.ymaps-2-1-74-islets_card__taxi, .ymaps-2-1-74-islets_card__taxi-link {
		padding-left: 0;
		color: #6A72A5;
	}
}

.ymaps-2-1-74-islets_serp-popup ::-webkit-scrollbar-thumb, .ymaps-2-1-74-islets_serp-popup::-webkit-scrollbar-thumb {
	background-color: rgba(#6A72A5, .5) !important;
	border-width: 6px !important;
}

