.swiper-slide .card--productMain {
	height: 100%;
}

.card--productMain {
	background: #9B51E0;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	color: #fff;
	padding: rem(27px) rem(28px) 0;
	overflow: hidden;
	display: flex;
	flex-flow: column;
	min-height: rem(437px);


	&:hover {

		@include rmin($xs-breakpoint) {
			.btn--cardArrow {

				&:before {
					width: rem(162px);
					height: rem(162px);
				}

				svg {
					transform: translateX(0);
					opacity: 1;
				}
			}
		}
	}


	&.card--productMainYellow {
		background: #FFDF00;
		color: #0E1750;
		padding: rem(38px) rem(28px) 0;


		.btn--cardArrow {

			&:before {
				background-color: darken(#FFDF00, 5%);
			}

			svg {
				fill: #000;
			}
		}

		.card__title {
			font-weight: 700;
			margin-bottom: rem(18px);
		}
	}


	> *:not(.btn):not(.card__link) {
		z-index: 2;
		position: relative;
	}

	.card__small {
		font-size: rem(12px);
		line-height: rem(14px);
		margin-top: rem(17px);
	}

	.card__title {
		font-size: rem(20px);
		line-height: rem(28px);
		margin-bottom: rem(16px);
	}

	.card__subtitle {
		font-size: rem(14px);
		line-height: rem(20px);

		@include r($xs-breakpoint) {
			padding-right: rem(10px);
		}

		br {

			@include r($xs-breakpoint) {
				display: none;
			}
		}

		p:not(:first-child) {
			margin-top: rem(29px);
		}
	}

	.card__photo {
		padding-top: rem(40px);
		margin-top: auto;
		margin-left: rem(-28px);
		margin-right: rem(-28px);
		text-align: center;
	}
}
