.containerFluid,
.container {
	width: 100%;
	padding: 0 15px;
	margin: 0 auto;
	position: relative;
}

.container {
	padding: 0 rem(67px);

	@include r($xs-breakpoint) {
		padding: 0 20px;
	}
}

.hiddenMobile {
	@include r($mob) {
		display: none !important;
	}
}

.hiddenDesktop {

	@include rmin($mob) {
		display: none !important;
	}
}

