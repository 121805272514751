.grid.grid--flex {
	display: flex;
	flex-flow: wrap;
	margin: -25px -10px;

	.grid__item {
		padding: 25px 10px;

		&:not(:first-child) {
			margin-top: 0;
		}
	}
}


@mixin grid-generator($size, $grid-columns) {
	@for $i from 1 through $grid-columns {
		.column#{$size}--#{$i} {
			//padding: 0 8px;
			width: percentage($i / $grid-columns);
		}
	}
}

@mixin grid-generator2($size, $grid-columns) {
	@for $i from 1 through $grid-columns {
		@include r($sm-breakpoint) {
			.column#{$size}--#{$i} {
				//padding: 0 8px;
				width: percentage($i / $grid-columns);
			}
		}
	}
}

@mixin padding-right-generator($size, $grid-columns) {
	@for $i from 1 through $grid-columns {
		.padding-right-#{$size}--#{$i} {
			padding-right: percentage($i / $grid-columns);
		}
	}
}

@mixin padding-left-generator($size, $grid-columns) {
	@for $i from 1 through $grid-columns {
		.padding-left-#{$size}-#{$i} {
			padding-left: percentage($i / $grid-columns);
		}
	}
}

@mixin padding-right-generator2($size, $grid-columns) {
	@for $i from 1 through $grid-columns {
		@include r($sm-breakpoint) {
			.padding-right-#{$size}-#{$i} {
				padding-right: percentage($i / $grid-columns);
			}
		}
	}
}

@mixin padding-left-generator2($size, $grid-columns) {
	@for $i from 1 through $grid-columns {
		@include r($sm-breakpoint) {
			.padding-left-#{$size}-#{$i} {
				padding-left: percentage($i / $grid-columns);
			}
		}
	}
}

@mixin margin-right-generator($size, $grid-columns) {
	@for $i from 1 through $grid-columns {
		.margin-right-#{$size}-#{$i} {
			margin-right: percentage($i / $grid-columns);
		}
	}
}

@mixin margin-left-generator($size, $grid-columns) {
	@for $i from 1 through $grid-columns {
		.margin-left-#{$size}-#{$i} {
			margin-left: percentage($i / $grid-columns);
		}
	}
}

@include grid-generator(Desktop, 12);

@include grid-generator2(Tablet, 12);

@include padding-left-generator(md, 12);
@include padding-right-generator(md, 12);

@include padding-left-generator2(sm, 8);
@include padding-right-generator2(sm, 8);

@include margin-left-generator(md, 12);
@include margin-right-generator(md, 12);

.grid .grid__item.columnsAuto {
	width: auto;
}

.columnsAuto {
	width: auto;
}
