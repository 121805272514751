h1,
h2,
h3,
h4 {
}

h1,
.h1 {
	font-size: rem(36px);
	color: #0e1750;
	font-weight: 700;

	@include r($xs-breakpoint) {
		font-size: 30px;
	}
}

.h1 + .h2 {
	margin-top: rem(15px);

	@include r($xs-breakpoint) {
		margin-top: rem(13px);
	}
}

.h1 + .text--md {
	margin-top: rem(45px);

	@include r($xs-breakpoint) {
		margin-top: rem(21px);
	}
}

h2,
.h2 {
	font-size: rem(20px);

	@include r($xs-breakpoint) {
		font-size: 20px;
	}

	&:not([class*="color"]) {
		color: $primary-color-light;
	}
}

.h2 + .text--md {
	margin-top: rem(28px);

	@include r($xs-breakpoint) {
		margin-top: rem(18px);
	}
}

.h2 + .sliderBox {
	margin-top: rem(29px);

	@include r($xs-breakpoint) {
		margin-top: rem(21px);
	}
}

h3,
.h3 {
	font-size: rem(18px);
}

.h3 + .text {
	margin-top: rem(13px);
}

h4 {
}

h5 {
}

h6 {
}

p {
	margin: 0;
}

.text {
	font-size: rem(14px);
	line-height: rem(20px);

	&.text--md {
		font-size: rem(16px);
		line-height: rem(26px);
	}

	&.text--xs {
		font-size: rem(12px);
		line-height: rem(14px);

		p:not(:first-child) {
			margin-top: rem(5px);
		}
	}

	a {
		color: $secondary-color;
	}
}

.text--md + .grid {
	margin-top: rem(43px);

	@include r($xs-breakpoint) {
		margin-top: rem(23px);
	}
}

.text--md + .grid--cardsDefault {
	margin-top: rem(25px);
}

.text--md + .grid--products {
	margin-top: rem(37px);

	@include r($xs-breakpoint) {
		margin-top: rem(25px);
	}
}

.text--md + .text.colorPrimaryLight {
	margin-top: rem(54px);

	@include r($xs-breakpoint) {
		margin-top: 30px;
	}
}

.text--md + .h2 {
	margin-top: rem(34px);

	@include r($xs-breakpoint) {
		margin-top: rem(44px);
	}
}

.grid {

	.grid__item:not(:first-child) {
		margin-top: rem(20px);

		@include r($xs-breakpoint) {
			margin-top: rem(10px);
		}
	}
}

.grid--cardsDifferent {

	.grid__item:not(:first-child) {

		@include r($xs-breakpoint) {
			margin-top: rem(20px);
		}
	}
}

.grid--products {
	margin-left: rem(-10px);
	margin-right: rem(-10px);
	margin-bottom: rem(-10px);
	display: flex;
	flex-flow: wrap;

	@include r($xs-breakpoint) {
		//display: inline-block;
		//white-space: nowrap;
		//overflow-x: auto;
		//overflow-y: hidden;
		margin: 0;


		.card {
			height: 100%;
		}
	}

	.grid__item {
		padding: 0 rem(10px) rem(10px);

		@include r($xs-breakpoint) {
			display: inline-block;
			white-space: normal;
		}

		&:not(:first-child) {
			margin-top: 0;
		}


		.card--lg {
			margin-top: rem(60px);

			@include r($xs-breakpoint) {
				margin-top: 0;
			}
		}

	}
}


.grid--products + .text--md {
	margin-top: rem(55px);
}

.grid--cardsDefault + .text--md {
	margin-top: rem(25px);

	@include r($xs-breakpoint) {
		margin-top: rem(31px);
	}
}

.grid--productsMain {
	margin: rem(50px) rem(-10px) 0;
	display: flex;
	flex-flow: wrap;

	.grid__item {
		padding: 0 rem(10px);
	}

	.h2 {
		margin-bottom: rem(58px);

		@include r($xs-breakpoint) {
			margin-bottom: rem(30px);
		}
	}
}

.grid + .text--xs {
	margin-top: rem(48px);

	@include r($xs-breakpoint) {
		margin-top: rem(20px);
	}
}

html {
	font-size: rem(16px);
	line-height: 160%;
}

.counterListBox {
	margin: rem(23px) 0;

	@include r($xs-breakpoint) {
		margin: rem(20px) 0;
	}

	ol {
		margin: 0;
		padding: 0;
		list-style-type: none;
		font-size: rem(14px);
		line-height: rem(22px);
		counter-reset: list;

		li {
			position: relative;
			padding-left: rem(28px);
			counter-increment: list;

			@include r($xs-breakpoint) {
				padding-left: rem(20px);
			}

			&:before {
				content: counter(list);
				position: absolute;
				top: 0;
				left: 0;
			}

			&:not(:first-child) {
				margin-top: rem(14px);

				@include r($xs-breakpoint) {
					margin-top: 14px;
				}
			}
		}

		a {
			color: $secondary-color;
		}
	}
}


.counterListBox + .h2 {
	margin-top: rem(65px);

	@include r($xs-breakpoint) {
		margin-top: rem(35px);
	}
}


.sliderBox {
	margin: rem(23px) 0;
	background: #9b51e0;
	border-radius: rem(4px);
	overflow: hidden;
	color: #fff;

	@include r($xs-breakpoint) {
		//overflow: visible;
		margin-right: rem(-20px);
		background: transparent;

	}

	.swiper-container {

		@include r($xs-breakpoint) {
			overflow: visible;
			padding-right: rem(40px);
		}

		.swiper-slide {

			@include rmin($xs-breakpoint) {
				opacity: 0 !important;
			}

			@include r($xs-breakpoint) {
				height: auto;
			}

			&.swiper-slide-active {

				@include rmin($xs-breakpoint) {
					opacity: 1 !important;


					.sliderBoxItem__bar {
						width: 100%;
					}
				}
			}
		}

		.swiper-pagination-wrapper {
			top: rem(40px);
			left: rem(80px);
			position: absolute;
			height: rem(4px);
			display: flex;

			@include r($xs-breakpoint) {
				top: auto;
				bottom: rem(26px);
				left: 50%;
				margin-left: rem(-20px);
				transform: translateX(-50%);
				z-index: 2;
			}


			.swiper-pagination-wrapper-el {
				position: absolute;
				margin: 0 5.5px;
				width: 16px;
				height: 4px;
				border-radius: 20px;
				background-color: #fff;
				left: 0;
				top: 0;
				transition: .3s all;

				@include r($xs-breakpoint) {
					top: auto;
					bottom: 0;
				}
			}

			.swiper-pagination {
				position: relative;
				bottom: 0;
				left: 0;
				right: 0;
				top: 0;
				display: inline-flex;
				align-items: center;

				&-bullet {
					background-color: #fff;
					opacity: .5;
					width: 4px;
					height: 4px;
					border-radius: 100%;
					transition: .3s all;
					margin: 0 5.5px;
					display: block;
					position: relative;

					&:before {
						content: '';
						position: absolute;
						top: -5px;
						right: -5px;
						bottom: -5px;
						left: -5px;
					}

					&-active {
						opacity: 1;
						width: 16px;
						border-radius: 20px;
						pointer-events: none;
					}
				}
			}
		}
	}

	.sliderBoxItem {
		padding: rem(45px) rem(27px);
		position: relative;
		min-height: rem(226px);
		display: flex;
		//align-items: flex-end;

		@include r($xs-breakpoint) {
			background: #9b51e0;
			flex-flow: column-reverse;
			padding: rem(0) rem(20px) rem(60px);
			align-items: center;
			border-radius: 4px;
			overflow: hidden;
		}

		&__left {
			position: relative;
			z-index: 1;
			max-width: rem(290px);
			display: flex;
			align-items: flex-end;
			height: rem(136px);

			@include r($xs-breakpoint) {
				text-align: center;
				height: auto;
			}

			br {

				@include r($xs-breakpoint) {
					display: none;
				}
			}
		}

		&__right {
			position: absolute;
			top: rem(13px);
			right: rem(13px);

			@include r($xs-breakpoint) {
				position: relative;
				top: 0;
				right: 0;
				margin-top: rem(-20px);
				margin-bottom: rem(-20px);
			}

			img {
				width: rem(200px);
				height: rem(200px);

				@include r($xs-breakpoint) {
					//width: rem(158px);
					//height: rem(158px);
				}
			}
		}

		&__number {
			position: absolute;
			top: rem(30px);
			left: rem(31px);
			font-size: rem(24px);
			font-weight: 700;

			@include r($xs-breakpoint) {
				display: none;
			}
		}

		&__bar {
			position: absolute;
			bottom: 0;
			left: 0;
			background-color: #FFDF00;
			height: 3px;
			border-radius: 20px;
			width: 0;
			transition: 3.2s width linear;
		}
	}

}

.sliderBox + .text--md {
	margin-top: rem(33px);

	@include r($xs-breakpoint) {
		margin-top: rem(41px);
	}
}

.sliderBox + .grid {
	margin-top: rem(37px);

	@include r($xs-breakpoint) {
		margin-top: rem(28px);
	}
}

.listBox {
	margin: rem(23px) 0;

	@include r($xs-breakpoint) {
		margin: rem(13px) 0;
	}

	ul {
		font-size: rem(14px);
		line-height: rem(22px);
		color: rgba(#0E1750, .8);


		li {
			position: relative;
			padding-left: rem(17px);

			&:before {
				content: '';
				position: absolute;
				top: 9px;
				left: 0;
				width: 4px;
				height: 4px;
				border: 1px solid $primary-color;
				border-radius: 100%;
			}

			&:not(:first-child) {
				margin-top: rem(14px);
			}
		}
	}
}


.listBox + .text--xs {
	margin-top: rem(40px);
}
